<script setup>
import dayjs from "dayjs";
import { Notyf } from "notyf";
import { ref, onMounted, watch } from "vue";
import { useSaleStore } from "@/store/sales";
import { t, getCurrentLanguage } from "@/utils/i18n";

const props = defineProps({
  dateRange: {
    type: Object,
    required: true,
  },
});

const notyf = new Notyf();
const saleStore = useSaleStore();
const chartConfiguration = ref({});
const totConunts = ref([]);
const total = ref(0);
const isLoading = ref(true);

const optionsChart = {
  chart: {
    id: "vuechart-example",
    fontFamily: "Inter, sans-serif",
    height: 320,
    type: "donut",
  },
};

const setDataChart = async (isoDates) => {
  try {
    const response = await saleStore.fetchCompanyTotalSales({
      isoDates,
    });
    chartConfiguration.value = optionsChart;
    total.value = response.total;

    const { companyNames, companyTotals } = extractCompanyAndTotal(response);

    const colors = generateUniqueColors(companyNames.length);
    totConunts.value = companyTotals;

    optionsChart.colors = colors;
    optionsChart.labels = companyNames;
    optionsChart.totals = companyTotals;

    isLoading.value = false;
  } catch (err) {
    const error = saleStore.error;

    let errorMessage = "Something went wrong. Please try again later.";

    if (error.response) {
      switch (error.response.status) {
        case 404:
          errorMessage = "Data not found. Please try a different query.";
          break;
        case 500:
          errorMessage = "Server error. Please try again later.";
          break;
        default:
          errorMessage = "Unexpected error occurred. Please try again.";
      }
    } else if (error.request) {
      errorMessage =
        "No response from the server. Please check your network connection.";
    }

    notyf.error(errorMessage);
  }
};

function extractCompanyAndTotal(response) {
  const companyNames = [];
  const companyTotals = [];

  const onlyCompanies = Object.keys(response).filter((key) => key !== "total");

  for (let i = 0; i < onlyCompanies.length; i++) {
    companyNames.push(onlyCompanies[i]);
    companyTotals.push(response[onlyCompanies[i]]);
  }

  return { companyNames, companyTotals };
}

function adjustColor(col, amt) {
  let hex = parseInt(col.slice(1), 16);
  let r = Math.min(255, Math.max(0, (hex >> 16) + amt));
  let g = Math.min(255, Math.max(0, ((hex >> 8) & 0x00ff) + amt));
  let b = Math.min(255, Math.max(0, (hex & 0x0000ff) + amt));
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

function generateUniqueColors(numColors) {
  const baseColors = ["#44044B", "#E22CA4", "#895E8B", "#ffb800"];
  const excludedColors = new Set(["#42cdff", "#fe0000", "#404C58", "#0ac327"]);
  const generatedColors = new Set();

  for (let i = 0; i < numColors; i++) {
    const baseColor = baseColors[i % baseColors.length];
    let newColor,
      variation = 0;

    do {
      newColor = adjustColor(baseColor, variation);
      variation += 20;
    } while (excludedColors.has(newColor) || generatedColors.has(newColor));

    generatedColors.add(newColor);
  }

  return Array.from(generatedColors);
}

watch(
  () => props.dateRange,
  (newDateRange) => {
    isLoading.value = true;
    setDataChart(newDateRange);
  }
);

onMounted(() => {
  setDataChart({ startDate: null, endDate: null, filterDate: "createdAt" });
});
</script>

<template>
  <div class="card flex-fill">
    <div class="card-header border-0">
      <div class="d-flex justify-content-between align-items-between">
        <h5 class="card-title">{{ t("dashboard.title2") }}</h5>
      </div>
    </div>
    <div class="card-body apexchart-index pt-0 h-100" style="min-height: 473px">
      <div class="border-dotted mb-3"></div>
      <div
        class="d-flex justify-content-center align-items-center h-100"
        v-if="isLoading"
      >
        <a-spin size="large" />
      </div>

      <div v-if="!isLoading">
        <div
          class="w-100 d-flex justify-content-center align-items-center position-relative"
        >
          <div class="position-absolute text-center">
            <p class="fs-5" style="color: #495057">
              {{ t("dashboard.text1") }}
            </p>
            <h5 class="fs-3">{{ total }}</h5>
          </div>

          <apexchart
            type="donut"
            height="350"
            class="w-100"
            :options="chartConfiguration"
            :series="totConunts"
          ></apexchart>
        </div>

        <div class="container mt-4">
          <a-collapse>
            <a-collapse-panel header="Totales">
              <div
                v-for="(label, index) in chartConfiguration.labels"
                :key="index"
                class="company-item mb-3"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <a-tag :color="chartConfiguration.colors[index]"></a-tag>
                    <h5 class="ms-2 mb-0">{{ label }}</h5>
                  </div>

                  <div class="text-end">
                    <p class="text-muted mb-0">Total ventas</p>
                    <span class="fw-bold">{{
                      chartConfiguration.totals[index]
                    }}</span>
                  </div>
                </div>
              </div>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
span.ant-tag {
  width: 30px;
  height: 20px;
}

.list-item {
  border-bottom: 2px dotted #dee2e6;
}

.chart-company {
  position: relative;
}

.total-center {
  position: absolute;
  top: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.company-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 1rem;
}

.company-item h5 {
  font-size: 14px;
  margin: 0;
}

.text-muted {
  font-size: 12px;
}

.fw-bold {
  font-weight: 600;
}
</style>
