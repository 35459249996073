import moment from "moment";

/**
 * Formats a date string to 'MMM/DD/YYYY' and converts it to uppercase.
 * @param {string} dateString - The date string to format.
 * @returns {string} - The formatted date string.
 */
export function formatDate(dateString) {
  if (!dateString) return "";

  const date = moment(dateString, moment.ISO_8601, true);
  return date.isValid() ? date.format("MMM/DD/YYYY").toUpperCase() : "";
}

export function formatDateToISO(dateString) {
  if (!dateString) return "";

  const date = moment(dateString, moment.ISO_8601, true);
  return date.isValid() ? date.toISOString() : "";
}
/**
 * Formats a timestamp to 'MMM/DD/YYYY' and converts it to uppercase.
 * @param {Object} timestamp - The timestamp object with _seconds property.
 * @returns {string} - The formatted date string.
 */
export function formatDateFromTimestamp(timestamp) {
  if (!timestamp || typeof timestamp._seconds === "undefined") return "";
  const { _seconds } = timestamp;
  const date = moment.unix(_seconds);
  return date.format("MMM/DD/YYYY, hh:mm A").toUpperCase();
}

/**
 * Combines first name and last name into a full name.
 * @param {string} firstName - The first name.
 * @param {string} lastName - The last name.
 * @returns {string} - The full name.
 */

export function getFullName(firstName = "", lastName = "") {
  return firstName || lastName ? `${firstName} ${lastName}`.trim() : null;
}

export function formatToISODate(dateString) {
  return moment(dateString).format("YYYY-MM-DD");
}

export function formatToCreatedAt(timestamp) {
  // Accede al valor de la propiedad 'value'
  const dateString = timestamp.value;
  const date = moment(dateString); // moment puede manejar el formato ISO 8601 directamente
  return date.isValid() ? date.format("MMM/DD/YYYY").toUpperCase() : "";
}

export function formatPhoneNumber(phone) {
  const newPhone = `(${phone.slice(0, 3)})${phone.slice(3, 6)}-${phone.slice(
    6
  )}`;
  return newPhone;
}

/**
 * Capitalizes the first letter of a word and makes the rest lowercase.
 * @param {string} word - The word to capitalize.
 * @returns {string} - The capitalized word.
 */

export function capitalize(word) {
  if (!word) return "";
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

export function getUser() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user !== null ? user : {};
}

export function calculatePercentages(total, values) {
  return values.map((value) => {
    const percentage = (value / total) * 100;
    return percentage % 1 === 0
      ? Math.round(percentage)
      : Math.round(percentage * 100) / 100;
  });
}

export function filterOutDraftSales(sales) {
  return sales.filter((sale) => sale.status !== "DRAFT");
}

export function maskCardNumber(number) {
  if (number.length <= 4) {
    return number;
  }
  return number.slice(0, -4).replace(/\d/g, "*") + number.slice(-4);
}

export function extractName(fullName) {
  const nameParts = fullName.trim().split(" ");

  if (nameParts.length <= 1) {
    return { firstName: nameParts[0] || "", middleName: "", lastName: "" };
  }

  if (nameParts.length === 2) {
    return { firstName: nameParts[0], middleName: "", lastName: nameParts[1] };
  }

  // Si hay 3 o más partes
  const firstName = nameParts[0];
  const middleName = nameParts[1];
  const lastName = nameParts.slice(2).join(" ");

  return { firstName, middleName, lastName };
}

export const statusColors = {
  draft: "#ffb800",
  assigned: "#42cdff",
  rejected: "#fe0000",
  corrected: "#404C58",
  submitted: "#0ac327",
  to_review: "#e5c1f5",
  pre_processing: "#40E0D0",
};
