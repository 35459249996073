<script setup>
import { ref } from "vue";
const dateRange = ref({
  startDate: null,
  endDate: null,
  filterDate: "createdAt",
});

function updateDateRange(dates) {
  dateRange.value = dates;
}

console.log("rangues", dateRange.value);
</script>

<template>
  <layout-header @dates-changed="updateDateRange"></layout-header>
  <layout-sidebar></layout-sidebar>

  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content container-fluid">
      <index-page :dateRange="dateRange"></index-page>
    </div>
  </div>
  <!-- /Page Wrapper -->
</template>
