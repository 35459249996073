<script setup>
import { ref, reactive, watch, defineProps, computed } from "vue";
import { getUser } from "@/utils/";
import { Notyf } from "notyf";
import gsap from "gsap";
import { t, getCurrentLanguage } from "@/utils/i18n";

const STATUS = {
  ASSIGNED: "ASSIGNED",
  REJECTED: "REJECTED",
  CORRECTED: "CORRECTED",
  SUBMITTED: "SUBMITTED",
  DRAFT: "DRAFT",
  TO_REVIEW: "TO_REVIEW",
  PRE_PROCESSING: "PRE_PROCESSING",
};

const props = defineProps({
  salesData: {
    type: Object,
    default: () => null,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["refresh"]);

const cards = ref([]);
const cardValues = ref({});
const notyf = new Notyf();
const tweenedValues = ref([]);
const roleUser = getUser().role;

// Create reactive references for titles that need to be translated
const cardTitles = computed(() => ({
  ASSIGNED: t("dashboard.cards.assigned"),
  REJECTED: t("dashboard.cards.rejected"),
  CORRECTED: t("dashboard.cards.corrected"),
  SUBMITTED: t("dashboard.cards.submitted"),
  DRAFT: t("dashboard.cards.draft"),
  TO_REVIEW: t("dashboard.cards.to_review"),
  PRE_PROCESSING: t("dashboard.cards.pre_processing"),
}));

// Modified baseCards to use the reactive cardTitles
const baseCards = computed(() => [
  {
    status: STATUS.ASSIGNED,
    title: cardTitles.value.ASSIGNED,
    icon: "feather feather-file-text",
    iconBg: "bg-2",
    progressBg: "bg-6",
    trend: "up",
    changePercent: "2.37%",
  },
  {
    status: STATUS.REJECTED,
    title: cardTitles.value.REJECTED,
    icon: "feather feather-x",
    iconBg: "bg-3",
    progressBg: "bg-7",
    trend: "up",
    changePercent: "3.77%",
  },
  {
    status: STATUS.CORRECTED,
    title: cardTitles.value.CORRECTED,
    icon: "feather feather-check-square",
    iconBg: "icon-bg-corrected",
    progressBg: "progress-bg-corrected",
    trend: "down",
    changePercent: "1.15%",
  },
  {
    status: STATUS.SUBMITTED,
    title: cardTitles.value.SUBMITTED,
    icon: "feather feather-check",
    iconBg: "bg-4",
    progressBg: "bg-8",
    trend: "down",
    changePercent: "8.68%",
  },
  {
    status: STATUS.TO_REVIEW,
    title: cardTitles.value.TO_REVIEW,
    icon: "feather feather-eye",
    iconBg: "bg-9",
    progressBg: "bg-9",
    trend: "down",
    changePercent: "8.68%",
  },
  {
    status: STATUS.PRE_PROCESSING,
    title: cardTitles.value.PRE_PROCESSING,
    icon: "feather feather-clock",
    iconBg: "bg-12",
    progressBg: "bg-13",
    trend: "up",
    changePercent: "4.25%",
  },
]);

const sellerCard = computed(() => ({
  status: STATUS.DRAFT,
  title: cardTitles.value.DRAFT,
  icon: "feather feather-edit",
  iconBg: "bg-1",
  progressBg: "bg-5",
  trend: "down",
  changePercent: "1.15%",
}));

function calculatePercentages(salesData) {
  const totalSales = Object.values(salesData).reduce(
    (acc, value) => acc + value,
    0
  );

  if (totalSales === 0) {
    return Object.fromEntries(Object.keys(salesData).map((key) => [key, 0]));
  }

  return Object.fromEntries(
    Object.entries(salesData).map(([key, value]) => {
      let percentage = (value / totalSales) * 100;
      percentage = parseFloat(percentage.toFixed(1));
      return [key, percentage];
    })
  );
}

function setCardByRole(role, values) {
  const percentages = calculatePercentages(values);

  cardValues.value = values;

  const cardData =
    role === "SELLER"
      ? [sellerCard.value, ...baseCards.value]
      : [...baseCards.value];

  cards.value = cardData.map((card) => ({
    ...card,
    value: values[card.status], // Use status instead of title for mapping values
    percent: percentages[card.status],
  }));

  tweenedValues.value = cards.value.map(() =>
    reactive({ currentValue: 0, currentPercent: 0 })
  );

  setupAnimations();
}

function setupAnimations() {
  cards.value.forEach((card, index) => {
    watch(
      () => card.value,
      (newValue) => {
        if (newValue !== undefined && newValue !== null && newValue !== "") {
          gsap.to(tweenedValues.value[index], {
            duration: 0.5,
            currentValue: newValue,
          });
        }
      }
    );

    watch(
      () => card.percent,
      (newPercent) => {
        gsap.to(tweenedValues.value[index], {
          duration: 0.5,
          currentPercent: newPercent,
        });
      }
    );

    if (card.value !== undefined && card.value !== null && card.value !== "") {
      gsap.to(tweenedValues.value[index], {
        duration: 0.5,
        currentValue: card.value,
        currentPercent: card.percent,
      });
    }
  });
}

const loadTotalCount = async (dataCount) => {
  try {
    if (!dataCount) return;
    const saleDataCount = dataCount[0];
    const {
      DRAFT = 0,
      ASSIGNED = 0,
      REJECTED = 0,
      CORRECTED = 0,
      SUBMITTED = 0,
      TO_REVIEW = 0,
      PRE_PROCESSING = 0,
    } = saleDataCount;

    let totStatus = {
      [STATUS.ASSIGNED]: ASSIGNED,
      [STATUS.REJECTED]: REJECTED,
      [STATUS.CORRECTED]: CORRECTED,
      [STATUS.SUBMITTED]: SUBMITTED,
      [STATUS.TO_REVIEW]: TO_REVIEW,
      [STATUS.PRE_PROCESSING]: PRE_PROCESSING,
    };

    if (roleUser === "SELLER") {
      totStatus = {
        [STATUS.DRAFT]: DRAFT,
        ...totStatus,
      };
    }

    setCardByRole(roleUser, totStatus);
  } catch (error) {
    console.error("Error in loadTotalCount:", error);
  }
};

watch(
  () => props.salesData,
  (newData) => {
    if (newData) {
      loadTotalCount(newData);
    }
  },
  { immediate: true } // Esto hace que se ejecute inmediatamente cuando el componente se monta
);
//Watch for language changes and update the cards
watch(getCurrentLanguage, () => {
  if (!isLoading.value && Object.keys(cardValues.value).length > 0) {
    setCardByRole(roleUser, cardValues.value);
  }
});

const handleRefresh = () => {
  emit("refresh");
};
</script>

<template>
  <div class="d-flex justify-content-end mb-3"></div>
  <div class="row d-flex justify-content-between" v-if="isLoading">
    <div
      class="col-xl-2 col-sm-6 col-12"
      :style="`min-width: ${100 / (roleUser === 'SELLER' ? 5 : 4)}%`"
      v-for="index in roleUser === 'SELLER' ? 5 : 4"
      :key="index"
    ></div>
  </div>

  <div class="row d-flex justify-content-between" v-if="!isLoading">
    <div
      v-for="(card, index) in cards"
      :key="index"
      class="col-xl-2 col-sm-6 col-12"
      :style="`min-width: ${100 / cards.length}%`"
    >
      <div class="card">
        <div class="card-body">
          <div class="dash-widget-header">
            <span :class="`dash-widget-icon ${card.iconBg}`">
              <i :class="card.icon"></i>
            </span>
            <div class="dash-count">
              <div class="dash-title">{{ card.title }}</div>
              <div class="dash-counts">
                <p>{{ tweenedValues[index].currentValue.toFixed(0) }}</p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="progress progress-sm me-2 w-100">
              <div
                class="progress-bar"
                :class="card.progressBg"
                role="progressbar"
                :style="{
                  width: `${tweenedValues[index].currentPercent.toFixed(0)}%`,
                }"
                :aria-valuenow="tweenedValues[index].currentPercent"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <span style="color: #404c58">{{ card.percent }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-3 {
  background-color: rgba(254, 0, 0, 0.25);
}

.bg-3 i {
  color: #fe0000;
}

.bg-4 {
  background-color: rgba(0, 255, 41, 0.3);
}

.bg-4 i {
  color: #0ac327;
}

.bg-7 {
  background-color: #fe0000;
}

.bg-8 {
  background-color: #0ac327;
}

.bg-9 {
  background-color: #e5c1f5;
}

.icon-bg-corrected {
  background-color: rgba(64, 76, 88, 0.3);
}

.icon-bg-corrected i {
  color: #404c58;
}

.progress-bg-corrected {
  background-color: #404c58;
}

.progress-bar {
  border-radius: 0.375rem;
}
</style>
