<script setup>
import moment from "moment";
import { ref, onMounted, computed, watch, defineEmits } from "vue";
import dayjs from "dayjs";
import { Notyf } from "notyf";
import { useUserStore } from "@/store/user";
import { useSaleStore } from "@/store/sales";
import debounce from "lodash/debounce";
import {
  formatDate,
  formatDateFromTimestamp,
  getFullName,
  extractName,
  formatToCreatedAt,
} from "@/utils";
import * as XLSX from "xlsx";
import { t } from "@/utils/i18n";
// Constants
const ITEMS_PER_PAGE = 50;
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const DEBOUNCE_DELAY = 300; // milliseconds

// Store instances
const notyf = new Notyf();
const userStore = useUserStore();
const saleStore = useSaleStore();
const emit = defineEmits(["salesDataFiltersUpdated", "refresh"]);

// Computed properties
const title = computed(() => t("sales.newSale.title"));
const text = computed(() => t("sales.newSale.btnNewSale"));
const path = "/sales/new-sale";

// User info
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;
const currentDate = moment().format("YYYY-MM-DD");
const lastMonthDate = moment().subtract(15, "day").format("YYYY-MM-DD");

const salesDataFilters = ref(null);
const state = ref({
  activeKey: "1",
  sales: [],
  salesTotal: 0,
  salesByStatus: {},
  userProcessor: [],
  errorMessage: "",
  salesFilter: "",
  salesSwitch: "createdAt",
  saleIds: [],
  selectedProcessorUid: "",
  isSearchValue: false,
  isReassignEnabled: false,
  currentPage: 1,
  dateRange: {
    startDate: lastMonthDate,
    endDate: currentDate,
    filterDate: "createdAt",
  },
});
const isLoading = ref(false);
const isFetchingSales = ref(false); // To prevent duplicate sales fetch

// Cache management (simplified)
const cache = ref(new Map());

const getFromCache = (key) => {
  const cached = cache.value.get(key);
  if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
    return cached.data;
  }
  return null;
};

const setCache = (key, data) => {
  cache.value.set(key, { data, timestamp: Date.now() });
};

const fetchSales = async (dateRange, filters) => {
  if (isFetchingSales.value) return;
  isFetchingSales.value = true;

  try {
    const params = {
      dateRange: {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        filterDate: dateRange.filterDate,
      },
      filters,
      page: state.value.currentPage,
      limit: ITEMS_PER_PAGE,
    };

    const cacheKey = JSON.stringify(params);
    const cached = getFromCache(cacheKey);

    if (cached) {
      state.value.sales = cached.sales;
      state.value.salesByStatus = cached.salesByStatus;
      state.value.salesTotal = cached.total;
      return;
    }

    const [salesData, salesByStatusData, totalSales] =
      await saleStore.fetchSalesByDateRangeAndStatus(
        params.dateRange,
        params.filters
      );

    const result = {
      sales: salesData,
      salesByStatus: salesByStatusData,
      total: totalSales,
    };

    setCache(cacheKey, result);
    state.value.sales = result.sales;
    state.value.salesByStatus = result.salesByStatus;
    state.value.salesTotal = result.total;
    state.value.errorMessage = "";
  } catch (error) {
    state.value.sales = [];
    state.value.salesByStatus = {};
    state.value.salesTotal = 0;
    state.value.errorMessage = error.message;
    notyf.error(state.value.errorMessage);
  } finally {
    isFetchingSales.value = false;
  }
};

const loadProcessors = async () => {
  try {
    const userData = await userStore.fetchUsers();
    state.value.userProcessor = userData.filter(
      (user) =>
        user.role === "PROCESSOR" && ["ACTIVE", "ASSET"].includes(user.state)
    );
  } catch (error) {
    console.error("Error loading processors", error);
    notyf.error("Error al cargar procesadores");
  }
};

const searchSales = debounce(async ({ value, attribute }) => {
  let searchFilter = "";
  if (value && attribute) {
    switch (attribute) {
      case "saleId":
        searchFilter = `saleId=${value}`;
        break;
      case "FullName": {
        const { firstName, middleName, lastName } = extractName(value);
        searchFilter = middleName
          ? `firstName=${firstName}&middleName=${middleName}&lastName=${lastName}`
          : `firstName=${firstName}&lastName=${lastName}`;
        break;
      }
      case "firstName":
        searchFilter = `firstName=${value}`;
        break;
      case "birthDate":
        searchFilter = `birthDate=${dayjs(value).format("YYYY-MM-DD")}`;
        break;
      case "email":
        searchFilter = `email=${value}`;
        break;
      case "phone":
        searchFilter = `phone=${value.replace(/[\s()\-]/g, "")}`;
        break;
      case "socialSecurityNumber":
        searchFilter = `socialSecurityNumber=${value.replace(/\D/g, "")}`;
        break;
      case "familyGroupId":
        searchFilter = `familyGroupId=${value}`;
        break;
    }
  }
  state.value.isSearchValue = !!value;
  await fetchSales(state.value.dateRange, searchFilter);
}, DEBOUNCE_DELAY);

const updateDateRange = (dates) => {
  if (!dates?.startDate || !dates?.endDate) return;
  state.value.dateRange = {
    startDate: dayjs(dates.startDate).format("YYYY-MM-DD"),
    endDate: dayjs(dates.endDate).format("YYYY-MM-DD"),
    filterDate: state.value.salesSwitch,
  };
  state.value.currentPage = 1;
  fetchSales(state.value.dateRange, state.value.salesFilter);
};

const handleSwitchChange = (value) => {
  state.value.salesSwitch = value ? "submittedDate" : "createdAt";
  updateDateRange(state.value.dateRange);
};

const handleSalesReassign = (uidList) => {
  state.value.isReassignEnabled = uidList.length > 0;
  state.value.saleIds = uidList;
};

const reasignedSales = async (saleIds, selectedProcessorUid) => {
  try {
    await saleStore.reasignedSales(saleIds, selectedProcessorUid);
    notyf.success("Reasignación completada exitosamente.");
    document.getElementById("btnModalClose")?.click();
    state.value.saleIds = [];
    state.value.isReassignEnabled = false;
    await fetchSales(state.value.dateRange, "");
  } catch (error) {
    notyf.error(
      "Error al completar la reasignación. Por favor intente nuevamente."
    );
  }
};

const exportToExcel = () => {
  const statusMap = {
    2: t("dashboard.cards.assigned"),
    3: t("dashboard.cards.rejected"),
    4: t("dashboard.cards.submitted"),
    5: t("dashboard.cards.corrected"),
    6: t("dashboard.cards.pre_processing"),
    7: t("dashboard.cards.to_review"),
  };
  const dataMap = {
    2: state.value.salesByStatus.ASSIGNED,
    3: state.value.salesByStatus.REJECTED,
    4: state.value.salesByStatus.SUBMITTED,
    5: state.value.salesByStatus.CORRECTED,
    6: state.value.salesByStatus.PRE_PROCESING,
    7: state.value.salesByStatus.TO_REVIEW,
  };
  const dataToExport = (
    dataMap[state.value.activeKey] ||
    state.value.sales ||
    []
  ).map((record) => ({
    ID: record.saleId,
    Nombre: getFullName(record.firstName, record.lastName),
    "Número de la Seguridad Social": record.socialSecurityNumber,
    "Fecha de Nacimiento": formatDate(record.birthDate),
    Carrier: record.carrier,
    "Correo Electrónico": record.email,
    Teléfono: record.phone,
    State: record.state,
    Compañia: record.sellerCompanyName,
    Vendedor: record.sellerFullName,
    Calidad: record.assignedToCalidadName,
    Pre_procesador: record.asignedToNamePreProcessor,
    Procesador: record.assignedToName,
    Estado: record.status,
    "Fecha de Creación": formatToCreatedAt(record.createdAt),
    "Fecha de Sometida": record.submittedDate
      ? formatToCreatedAt(record.submittedDate)
      : "",
    "Grupo Familar": record.familyGroupId,
  }));

  const worksheet = XLSX.utils.json_to_sheet(dataToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Datos de Ventas");
  XLSX.writeFile(
    workbook,
    `Reporte_Ventas_${(
      statusMap[state.value.activeKey] || "todos"
    ).toLowerCase()}_${dayjs().format("YYYY-MM-DD")}.xlsx`
  );
};

const handlePageChange = (page) => {
  state.value.currentPage = page;
  fetchSales(state.value.dateRange, state.value.salesFilter);
};

watch(
  () => [state.value.activeKey, state.value.dateRange],
  () => {
    state.value.currentPage = 1;
    fetchSales(state.value.dateRange, state.value.salesFilter);
  }
);

watch(salesDataFilters, (newValue) => {
  emit("salesDataFiltersUpdated", newValue);
});
onMounted(async () => {
  await Promise.all([
    fetchSales(state.value.dateRange, state.value.salesFilter),
    loadProcessors(),
  ]);
});
</script>

<template>
  <layout-header
    @dates-changed="updateDateRange"
    @searchSales="searchSales"
    @filters="handleSwitchChange"
  />
  <layout-sidebar />

  <div class="page-wrapper">
    <div class="content container-fluid content-sales">
      <recurringheader
        :title="title"
        :path="path"
        :text="text"
        :error="state.errorMessage"
      />
      <searchfilter />
      <sales-card :dateRange="state.dateRange" :sales-data="state.sales" />

      <div class="filter-container">
        <a-tabs v-model:activeKey="state.activeKey">
          <a-tab-pane key="1" :tab="t('dashboard.cards.all')">
            <data-table
              :data="state.sales"
              :salesTotal="state.salesTotal"
              :activeKey="state.activeKey"
              @onSalesReassignRequested="handleSalesReassign"
              @page-change="handlePageChange"
              :current-page="state.currentPage"
              :items-per-page="ITEMS_PER_PAGE"
            />
          </a-tab-pane>
          <a-tab-pane key="2" :tab="t('dashboard.cards.assigned')" force-render>
            <data-table
              :data="state.salesByStatus.ASSIGNED || []"
              @onSalesReassignRequested="handleSalesReassign"
              @page-change="handlePageChange"
              :current-page="state.currentPage"
              :items-per-page="ITEMS_PER_PAGE"
            />
          </a-tab-pane>
          <a-tab-pane key="3" :tab="t('dashboard.cards.rejected')" force-render>
            <data-table
              :data="state.salesByStatus.REJECTED || []"
              @onSalesReassignRequested="handleSalesReassign"
              @page-change="handlePageChange"
              :current-page="state.currentPage"
              :items-per-page="ITEMS_PER_PAGE"
            />
          </a-tab-pane>
          <a-tab-pane
            key="4"
            :tab="t('dashboard.cards.submitted')"
            force-render
          >
            <data-table
              :data="state.salesByStatus.SUBMITTED || []"
              @page-change="handlePageChange"
              :current-page="state.currentPage"
              :items-per-page="ITEMS_PER_PAGE"
            />
          </a-tab-pane>
          <template #rightExtra>
            <div
              class="input-block mb-4 d-flex align-items-center justify-content-between"
            >
              <button
                type="button"
                class="btn btn-primary me-3 rounded-2"
                v-if="state.isReassignEnabled"
                data-bs-toggle="modal"
                data-bs-target="#reassignDocumentModal"
              >
                <i class="feather feather-repeat"></i>
                {{ t("sales.modal.reasignedProcessor.btnreassign") }}
              </button>
              <button
                v-if="['ADMIN', 'SUPERVISOR', 'JCALIDAD'].includes(userRole)"
                type="button"
                @click="exportToExcel"
                class="btn btn-outline-primary2"
              >
                <i class="fas fa-download"></i> Exportar Excel
              </button>
            </div>
          </template>
        </a-tabs>
      </div>

      <!-- Reassign Modal -->
      <div
        class="modal fade"
        id="reassignDocumentModal"
        tabindex="-1"
        aria-labelledby="reassignDocumentModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="reassignDocumentModalLabel">
                {{ t("sales.modal.reasignedProcessor.reassign") }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div class="modal-body text-left p-4">
              <p class="mb-0">
                {{ t("sales.modal.reasignedProcessor.txtreassign") }}
              </p>

              <div class="carrier-select-container me-3 mt-3 w-100">
                <select
                  ref="processorSelect"
                  class="form-control"
                  v-model="state.selectedProcessorUid"
                >
                  <option value="">
                    {{ t("sales.modal.reasignedProcessor.pch") }}
                  </option>
                  <option
                    v-for="processor in state.userProcessor"
                    :key="processor.uid"
                    :value="processor.uid"
                  >
                    {{ processor.firstName }} {{ processor.lastName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="modal-footer mt-4 p-4 pt-0">
              <button
                type="button"
                class="btn btn-outline-primary2 me-3"
                data-bs-dismiss="modal"
                id="btnModalClose"
              >
                {{ t("sales.modal.reasignedProcessor.btnCancel") }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="
                  reasignedSales(state.saleIds, state.selectedProcessorUid)
                "
              >
                {{ t("sales.modal.reasignedProcessor.btnContinue") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
