<script setup>
import moment from "moment";
import { defineProps, defineEmits } from "vue";
import { ref, onMounted, watch } from "vue";
import { getUser } from "@/utils/";
import { t } from "@/utils/i18n";
import { useSaleStore } from "@/store/sales";

import BarChartMonthlySales from "./components/bar-chart-monthly-sales.vue";
import DonutChartCompanies from "./components/donut-chart-companies-sales.vue";
import DonutChartStatus from "./components/donut-chart-status-sales.vue";
import BarChartPlansSales from "./components/bar-chart-plans-sales.vue";

const saleStore = useSaleStore();
const salesDataFilters = ref(null);
const isLoading = ref(false);
const emit = defineEmits(["salesDataFiltersUpdated", "refresh"]);

const props = defineProps({
  dateRange: {
    type: Object,
    required: true,
  },
});

const firstName = ref("");
const userRole = ref("");

function getFirstName(fullName) {
  if (typeof fullName !== "string" || !fullName.trim()) {
    throw new Error("Invalid input: fullName must be a non-empty string.");
  }

  const [firstName] = fullName.trim().split(" ");
  return firstName;
}

const loadSalesData = async (newDateRange) => {
  try {
    isLoading.value = true;
    const defaultEndDate = moment().format("YYYY-MM-DD");
    const defaultStartDate = moment().subtract(15, "day").format("YYYY-MM-DD");

    const filterParams = {
      startDate: newDateRange?.startDate || defaultStartDate,
      endDate: newDateRange?.endDate || defaultEndDate,
      filterDate: newDateRange?.filterDate || "createdAt",
    };
    const data = await saleStore.fetchSalesCounts(filterParams);
    salesDataFilters.value = data;

    // Asignar los datos directamente
    salesDataFilters.value = data;
  } catch (error) {
    console.error("Error loading sales data:", error);
    // Manejar el error apropiadamente
    salesDataFilters.value = null;
  } finally {
    isLoading.value = false;
  }
};

watch(salesDataFilters, (newValue) => {
  emit("salesDataFiltersUpdated", newValue);
});

onMounted(async () => {
  const user = getUser();
  userRole.value = user.role;
  firstName.value = getFirstName(user.name);
});

watch(
  () => props.dateRange,
  async (newDateRange) => {
    if (newDateRange) {
      isLoading.value = true;
      await loadSalesData(newDateRange);
    }
  },
  { immediate: true } // Esto asegura que se ejecute al montar el componente
);
</script>

<template>
  <div class="content-page-header">
    <div>
      <h5>
        {{ t("dashboard.hi") }},
        <span class="text-black">{{ firstName }}</span>
      </h5>
      <p class="text-secondary pt-2">{{ t("dashboard.text") }}</p>
    </div>
  </div>

  <main class="secction-dashboard">
    <div v-if="userRole === 'ADMIN' || userRole === 'SUPERVISOR'">
      <div class="row">
        <div class="col-xl-6 d-flex">
          <DonutChartCompanies :dateRange="dateRange" />
        </div>
        <div class="col-xl-6 d-flex">
          <DonutChartStatus
            :dateRange="dateRange"
            :sales-data="salesDataFilters"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 d-flex">
          <BarChartMonthlySales />
        </div>
        <div class="col-xl-6 d-flex">
          <BarChartPlansSales />
        </div>
      </div>
    </div>
    <div v-if="userRole !== 'ADMIN' && userRole !== 'SUPERVISOR'">
      <div class="row">
        <div class="col-xl-12 d-flex">
          <DonutChartStatus
            :dateRange="dateRange"
            :sales-data="salesDataFilters"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6 d-flex">
          <BarChartMonthlySales />
        </div>
        <div class="col-xl-6 d-flex">
          <BarChartPlansSales />
        </div>
      </div>
    </div>
  </main>
</template>
