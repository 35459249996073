<template>
  <!-- Add Asset -->
  <div class="toggle-sidebar">
    <div class="sidebar-layout-filter">
      <div class="sidebar-header">
        <h5>Filter</h5>
        <a href="javascript:;" class="sidebar-closes"
          ><i class="fa-regular fa-circle-xmark"></i
        ></a>
      </div>
      <div class="sidebar-body">
        <form action="javascript:;" autocomplete="off">
          <!-- Customer -->
          <div class="accordion" id="accordionMain1">
            <div class="card-header-new" id="headingOne">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Customer
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseOne"
              class="collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample1"
            >
              <div class="card-body-chat">
                <div class="row">
                  <div class="col-md-12">
                    <div id="checkBoxes1">
                      <div class="form-custom">
                        <input
                          type="text"
                          class="form-control"
                          id="member_search1"
                          placeholder="Search here"
                        />
                        <span
                          ><img src="@/assets/img/icons/search.svg" alt="img"
                        /></span>
                      </div>
                      <div class="selectBox-cont">
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Laura Lopez
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Sharol Polo
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Roberto Jimenez
                        </label>
                        <label class="custom_check w-100">
                          <input type="checkbox" name="username" />
                          <span class="checkmark"></span> Norida Herrazo
                        </label>
                        <!-- View All -->
                        <div class="view-content">
                          <div class="viewall-One">
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Pricilla
                            </label>
                            <label class="custom_check w-100">
                              <input type="checkbox" name="username" />
                              <span class="checkmark"></span> Randall
                            </label>
                          </div>
                          <div class="view-all">
                            <a
                              href="javascript:void(0);"
                              class="viewall-button-One"
                              ><span class="me-2">View All</span
                              ><span
                                ><i class="fa fa-circle-chevron-down"></i></span
                            ></a>
                          </div>
                        </div>
                        <!-- /View All -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Customer -->

          <!-- Select Date -->
          <div class="accordion" id="accordionMain2">
            <div class="card-header-new" id="headingTwo">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Select Date
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseTwo"
              class="collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample2"
            >
              <div class="card-body-chat">
                <div class="input-block mb-3">
                  <label class="form-control-label">From</label>
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate"
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
                <div class="input-block mb-3">
                  <label class="form-control-label">To</label>
                  <div class="cal-icon">
                    <date-picker
                      v-model="startdate1"
                      class="datetimepicker form-control"
                      :editable="true"
                      :clearable="false"
                      :input-format="dateFormat"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Select Date -->

          <!-- By Status -->
          <div class="accordion accordion-last" id="accordionMain3">
            <div class="card-header-new" id="headingThree">
              <h6 class="filter-title">
                <a
                  href="javascript:void(0);"
                  class="w-100 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  By Status
                  <span class="float-end"
                    ><i class="fa-solid fa-chevron-down"></i
                  ></span>
                </a>
              </h6>
            </div>

            <div
              id="collapseThree"
              class="collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample3"
            >
              <div class="card-body-chat">
                <div id="checkBoxes2">
                  <div class="form-custom">
                    <input
                      type="text"
                      class="form-control"
                      id="member_search2"
                      placeholder="Search here si"
                    />
                    <span
                      ><img src="@/assets/img/icons/search.svg" alt="img"
                    /></span>
                  </div>
                  <div class="selectBox-cont">
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Active
                    </label>
                    <label class="custom_check w-100">
                      <input type="checkbox" name="bystatus" />
                      <span class="checkmark"></span> Restricted
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /By Status -->
          <div class="filter-buttons">
            <button
              type="submit"
              class="d-inline-flex align-items-center justify-content-center btn w-100 btn-primary"
            >
              Apply
            </button>
            <button
              type="submit"
              class="d-inline-flex align-items-center justify-content-center btn w-100 btn-secondary"
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--/Add Asset -->

  <div class="modal custom-modal modal-lg fade" id="add_company" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <div class="form-header modal-header-title text-start mb-0">
            <h4 class="mb-0">{{ t("sales.users.addCompany") }}</h4>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="addCompany">
            <div class="mb-3">
              <label for="company-name" class="form-label">{{
                t("sales.users.nameCompany")
              }}</label>
              <input
                type="text"
                id="company-name"
                v-model="newCompanyName"
                class="form-control"
                :placeholder="t('sales.users.nameCompany')"
                required
              />
            </div>
            <div class="text-end">
              <button type="submit" class="btn btn-primary">
                {{ t("sales.users.createCompany") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Add User -->
  <div class="modal custom-modal modal-lg fade" id="add_user" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <div class="form-header modal-header-title text-start mb-0">
            <h4 class="mb-0">{{ t("sales.users.add") }}</h4>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body">
                <div class="form-groups-item">
                  <h5 class="form-title">
                    {{ t("sales.users.newUsers.profile") }}
                  </h5>
                  <div class="profile-picture">
                    <div class="upload-profile">
                      <div class="profile-img">
                        <img
                          id="blah"
                          class="avatar"
                          src="@/assets/img/profiles/avatar-10.jpg"
                          alt="profile-img"
                        />
                      </div>
                      <div class="add-profile">
                        <h5>{{ t("sales.users.newUsers.newPhoto") }}</h5>
                        <input type="file" @change="handleFileChange" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.firstName") }}</label>

                        <input
                          v-model="userData.firstName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.firstName')
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{
                          t("sales.users.newUsers.middlename")
                        }}</label>

                        <input
                          v-model="userData.middleName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.middlename')
                          "
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.lastName") }}</label>

                        <input
                          v-model="userData.lastName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.lastName')
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.email") }}</label>

                        <input
                          v-model="userData.email"
                          type="email"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.email')
                          "
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.phone") }}</label>

                        <input
                          v-model="userData.phone"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.phone')
                          "
                          name="name"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.role") }}</label>
                        <select
                          v-model="userData.role"
                          class="form-control"
                          required
                        >
                          <option value="" disabled>
                            {{ "Select " + t("sales.users.newUsers.role") }}
                          </option>
                          <option
                            v-for="role in Userrole"
                            :key="role.value"
                            :value="role.value"
                          >
                            {{ role.text }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      v-if="
                        userData.role === 'SELLER' ||
                        userData.role === 'CALIDAD' ||
                        userData.role === 'JCALIDAD'
                      "
                    >
                      <div class="input-block mb-3">
                        <label for="company" class="form-label">{{
                          t("sales.users.newUsers.company")
                        }}</label>
                        <select
                          v-model="userData.company"
                          class="form-control control"
                        >
                          <option value="">
                            {{ t("sales.users.newUsers.selectCompany") }}
                          </option>
                          <option
                            v-for="option in companyOptions"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-if="userData.role === 'PROCESSOR'">
                      <!-- <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.carrier") }}</label>
                        <select
                          ref="carrierSelect"
                          v-model="userData.carrier[0].carrier"
                          class="form-control control"
                        >
                          <option value="">
                            {{ t("sales.users.newUsers.selectCarrier") }}
                          </option>
                          <option
                            v-for="option in carrierOptions"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                      <div v-if="userData.carrier[0].carrier">
                        <div class="input-block mb-3">
                          <label>{{ t("sales.users.newUsers.state") }}</label>
                          <select
                            v-model="userData.carrier[0].states"
                            class="form-control control select-multiple"
                            multiple
                          >
                            <option value="" disabled>
                              {{ t("sales.users.newUsers.selectCarrier") }}
                            </option>
                            <option
                              v-for="state in statesOptions"
                              :key="state.value"
                              :value="state.value"
                            >
                              {{ state.label }}
                            </option>
                          </select>
                        </div>
                      </div> -->
                      <carrierTable
                        :optionsCarrier="carrierOptions"
                        :optionsStates="statesOptions"
                        :products="userData.carrier"
                        @update:products="updateCarrier"
                      />
                      <pre>{{ userData.carrier }}</pre>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="pass-group" id="3">
                        <div class="input-block">
                          <label>{{
                            t("sales.users.newUsers.password")
                          }}</label>

                          <input
                            v-model="userData.password"
                            type="password"
                            class="form-control pass-input"
                            placeholder=""
                            required
                          />
                          <span class="toggle-password feather-eye"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="pass-group" id="passwordInput2">
                        <div class="input-block">
                          <label>{{
                            t("sales.users.newUsers.confirmPassword")
                          }}</label>
                          <input
                            type="password"
                            class="form-control pass-input"
                            placeholder=""
                            required
                          />
                          <span class="toggle-password feather-eye"></span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block">
                        <label>{{ t("sales.users.newUsers.state") }}</label>
                        <vue-select
                          v-model="userData.state"
                          :options="UserStatus"
                          id="userstatus"
                          :placeholder="
                            'Select ' + t('sales.users.newUsers.state')
                          "
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- <a href="javascript:;" data-bs-dismiss="modal" class="btn btn-back cancel-btn me-2">Cancel</a> -->
          <a
            href="javascript:;"
            data-bs-dismiss="modal"
            class="btn btn-primary paid-continue-btn"
            @click="handlRegister"
            >{{ t("sales.users.add") }}</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- /Add User -->

  <!-- Add User -->
  <div class="modal custom-modal modal-lg fade" id="edit_user" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header border-0 pb-0">
          <div class="form-header modal-header-title text-start mb-0">
            <h4 class="mb-0">Edit User</h4>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="card-body">
                <div class="form-groups-item">
                  <!-- Profile Picture Section -->
                  <h5 class="form-title">Profile Picture</h5>
                  <div class="profile-picture">
                    <div class="upload-profile">
                      <div class="profile-img">
                        <img
                          id="blah2"
                          class="avatar"
                          :src="editedUser.profileUrl"
                          alt="profile-img"
                        />
                      </div>
                      <div class="add-profile">
                        <h5>Upload a New Photo</h5>
                        <input type="file" @change="handleFileChangeEdit" />
                      </div>
                    </div>
                  </div>

                  <!-- Form Inputs -->
                  <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.firstName") }}</label>
                        <input
                          v-model="editedUser.firstName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.firstName')
                          "
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{
                          t("sales.users.newUsers.middlename")
                        }}</label>
                        <input
                          v-model="editedUser.middleName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.middlename')
                          "
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.lastName") }}</label>
                        <input
                          v-model="editedUser.lastName"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.lastName')
                          "
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.email") }}</label>
                        <input
                          v-model="editedUser.email"
                          type="email"
                          class="form-control"
                          placeholder="
                            'Enter ' + t('sales.users.newUsers.email')
                          "
                          disabled
                        />
                      </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.phone") }}</label>
                        <input
                          v-model="editedUser.phone"
                          type="text"
                          class="form-control"
                          :placeholder="
                            'Enter ' + t('sales.users.newUsers.phone')
                          "
                        />
                      </div>
                    </div>

                    <!-- Role Select -->
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.role") }}</label>
                        <vue-select
                          v-model="editedUser.Role"
                          :options="Userrole"
                          :placeholder="
                            'Select ' + t('sales.users.newUsers.role')
                          "
                          required
                        />
                      </div>
                    </div>

                    <!-- Conditional Company Input for SELLER Role -->
                    <div
                      class="col-lg-4 col-md-6 col-sm-12"
                      v-if="
                        editedUser.Role === 'SELLER' ||
                        editedUser.Role === 'CALIDAD' ||
                        editedUser.Role === 'JCALIDAD'
                      "
                    >
                      <div class="input-block mb-3">
                        <label for="company" class="form-label">{{
                          t("sales.users.newUsers.company")
                        }}</label>

                        <select
                          v-model="editedUser.Company"
                          class="form-control control"
                        >
                          <option value="">
                            {{ t("sales.users.newUsers.selectCompany") }}
                          </option>
                          <option
                            v-for="option in companyOptions"
                            :key="option.value"
                            :value="option.value"
                          >
                            {{ option.label }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div v-if="editedUser.Role === 'PROCESSOR'">
                      <carrierTable
                        :data="editedUser.carrier"
                        :optionsCarrier="carrierOptions"
                        :optionsStates="statesOptions"
                        @update:products="updateCarrier"
                      />
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <div class="input-block mb-3">
                        <label>{{ t("sales.users.newUsers.state") }}</label>
                        <vue-select
                          v-model="editedUser.state"
                          :options="UserStatusone"
                          id="userstatusone"
                          :placeholder="
                            'Select ' + t('sales.users.newUsers.state')
                          "
                        />
                      </div>
                    </div>
                  </div>
                  <!-- End of Row -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a
            href="javascript:;"
            data-bs-dismiss="modal"
            class="btn btn-primary paid-cancel-btn me-2"
            >Cancel</a
          >
          <a
            href="javascript:;"
            data-bs-dismiss="modal"
            class="btn btn-primary paid-continue-btn"
            @click="handlEdit"
            >Save Changes</a
          >
        </div>
      </div>
    </div>
  </div>
  <!-- /Edit User -->

  <!-- Delete Items Modal -->
  <div class="modal custom-modal fade" id="delete_modal" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-body">
          <div class="form-header">
            <h3>Delete Users</h3>
            <p>Are you sure want to delete it?</p>
          </div>
          <div class="modal-btn delete-action">
            <div class="row">
              <div class="col-6">
                <a href="javascript:;" class="btn btn-primary paid-continue-btn"
                  >Delete</a
                >
              </div>
              <div class="col-6">
                <a
                  href="javascript:;"
                  data-bs-dismiss="modal"
                  class="btn btn-primary paid-cancel-btn"
                  >Cancel</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Delete Items Modal -->
</template>

<script setup>
import { ref, watch, onMounted, defineProps, defineEmits, computed } from "vue";
import { useUserStore } from "@/store/user";
import { Notyf } from "notyf";
import { t } from "@/utils/i18n";
import carrierTable from "./carrierTable.vue";

const currentDate = ref(new Date());
const currentDate1 = ref(new Date());
let profilePic = ref("");
const userInfo = ref({});
const carriers = ref([]);
const states = ref([]);
const companies = ref([]);
const newCompanyName = ref("");
const notyf = new Notyf();

const startdate = currentDate;
const startdate1 = currentDate1;
const dateFormat = "dd-MM-yyyy";
const Userrole = [
  { value: "", text: "Select Role" },
  { value: "PROCESSOR", text: "Procesador" },
  { value: "SELLER", text: "Vendedor" },
  { value: "SUPERVISOR", text: "Supervisor" },
  { value: "CALIDAD", text: "Calidad" },
  { value: "JCALIDAD", text: "Jefe Calidad" },
  { value: "ADMIN", text: "Administrador" },
  { value: "PRE_PROCESSOR", text: "Pre Procesador" },
  { value: "CUSTOMER_SERVICE", text: "Customer Service" },
  { value: "AUDITOR", text: "Auditor" },
  { value: "COORDINADOR_PAGOS", text: "Coordinador de Pagos" },
];
const UserStatus = ["Select Status", "ACTIVE", "INACTIVE"];
const UserStatusone = ["Select", "ACTIVE", "INACTIVE"];

const userStore = useUserStore();
const userData = userStore.userData;
const props = defineProps({
  data: Object,
});
const editedUser = computed(() => props["data"]);
const emit = defineEmits(["user-added", "user-edit"]);
const placeholderText = computed(() => t("sales.users.nameCompany"));

const handlRegister = async () => {
  console.log("que recibo de carrier", userData);
  if (userData.role != "PROCESSOR") {
    delete userData.carrier;
  }
  const response = await userStore.registerUser(userData);
  if (response && response.message) {
    if (Array.isArray(response.message)) {
      response.message.forEach((message) => notyf.error(message));
    } else {
      notyf.error(response.message);
    }
  } else {
    emit("user-added", userData);
    notyf.success("User successfully created");
  }
};

const handlEdit = async () => {
  userInfo.value = {
    email: editedUser.value.email,
    phone: editedUser.value.phone,
    role: editedUser.value.Role,
    firstName: editedUser.value.firstName,
    middleName: editedUser.value.middleName,
    lastName: editedUser.value.lastName,
    state: editedUser.value.state,
    uid: editedUser.value.uid,
    photo: userInfo.value.profileUrl,
  };

  if (editedUser.value.Role === "PROCESSOR") {
    userInfo.value.carrier = editedUser.value.carrier || "";
  } else if (
    editedUser.value.Role === "SELLER" ||
    editedUser.value.Role === "CALIDAD" ||
    editedUser.value.Role === "JCALIDAD"
  ) {
    userInfo.value.carrier = editedUser.value.carrier || "";
    userInfo.value.company = editedUser.value.Company;
  }
  // For ADMIN, we don't add any additional fields
  const response = await userStore.editUser(userInfo.value);
  if (response && response.message) {
    if (Array.isArray(response.message)) {
      response.message.forEach((message) => notyf.error(message));
    } else {
      notyf.error(response.message);
    }
  } else {
    emit("user-edit", editedUser.value);
    notyf.success("User successfully Edit");
  }
};

const handleFileChange = async (event) => {
  profilePic.value = event.target.files[0];
  userData.photo = profilePic.value;
};

const handleFileChangeEdit = async (event) => {
  profilePic.value = event.target.files[0];
  userInfo.value.profileUrl = profilePic.value;
};

const updateCarrier = (newCarrierData) => {
  userData.carrier = newCarrierData;
};

onMounted(() => {
  document.addEventListener("DOMContentLoaded", function () {
    const popupToggleElements = document.getElementsByClassName("popup-toggle");
    const toggleSidebarElement = document.querySelector(".toggle-sidebar");
    const bodyElement = document.body;

    if (popupToggleElements.length > 0) {
      for (let i = 0; i < popupToggleElements.length; i++) {
        popupToggleElements[i].addEventListener("click", function () {
          toggleSidebarElement.classList.add("open-filter");
          bodyElement.classList.add("filter-opened");
        });
      }

      const sidebarCloseElements =
        document.getElementsByClassName("sidebar-closes");
      for (let i = 0; i < sidebarCloseElements.length; i++) {
        sidebarCloseElements[i].addEventListener("click", function () {
          toggleSidebarElement.classList.remove("open-filter");
          bodyElement.classList.remove("filter-opened");
        });
      }
    }
  });
  document.addEventListener("DOMContentLoaded", function () {
    function initializeViewAll(classPrefix) {
      var viewallOnes = document.getElementsByClassName(
        "viewall-" + classPrefix
      );
      var viewallButton = document.getElementsByClassName(
        "viewall-button-" + classPrefix
      )[0];

      if (viewallOnes.length > 0 && viewallButton) {
        for (var i = 0; i < viewallOnes.length; i++) {
          viewallOnes[i].style.display = "none";
        }

        viewallButton.addEventListener("click", function () {
          var buttonText = viewallButton.textContent;
          for (var i = 0; i < viewallOnes.length; i++) {
            viewallOnes[i].style.display =
              viewallOnes[i].style.display === "none" ? "block" : "none";
          }

          viewallButton.textContent =
            buttonText === "Close All" ? "View All" : "Close All";
        });
      }
    }

    if (document.getElementsByClassName("viewall-One").length > 0) {
      initializeViewAll("One");
    }

    if (document.getElementsByClassName("viewall-Two").length > 0) {
      initializeViewAll("Two");
    }
  });
});
const loadCarrier = async () => {
  try {
    const response = await userStore.fetchCarrier();
    carriers.value = response[0]?.list;
  } catch (error) {
    console.error("Error loading carriers 111", error);
  }
};

const carrierOptions = computed(() => {
  return carriers.value.map((c) => ({
    label: c.value,
    value: c.id,
  }));
});

const loadStates = async () => {
  try {
    const response = await userStore.fetchStates();
    states.value = response[0]?.list;
  } catch (error) {
    console.error("Error loading statse", error);
  }
};

const statesOptions = computed(() => {
  return (
    states.value?.map((state) => ({
      label: state.value,
      value: state.id,
    })) || []
  );
});

const loadCompanies = async () => {
  try {
    const response = await userStore.fetchCompanies();
    companies.value = response[0];
  } catch (error) {
    console.error("Error loading companies", error);
  }
};

const addCompany = async () => {
  if (!newCompanyName.value.trim()) {
    return;
  }

  const formattedName = newCompanyName.value.trim();

  try {
    const result = await userStore.addCompany({
      id: formattedName.replace(/\s+/g, "_"),
      value: formattedName.toUpperCase(),
    });

    if (result.success) {
      const newCompany = {
        label: result.data.value,
        id: result.data.id,
      };

      companies.value.push(newCompany);
      newCompanyName.value = "";

      notyf.success("Company added successfully!");
    } else {
      console.error("Error adding company:", result.message);
    }
  } catch (error) {
    console.error("Error during company addition:", error);
  }
};

const companyOptions = computed(() => {
  return companies.value.map((c) => ({
    label: c.value,
    value: c.id,
  }));
});

onMounted(loadCompanies);

onMounted(loadCarrier);

onMounted(loadStates);

onMounted(() => {
  if (!userData.carrier || userData.carrier.length === 0) {
    userData.carrier = [{ carrier: "", states: [] }];
  }
});
watch(() => props["data"]);
</script>

<style scoped>
.input-block {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.control {
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #51585e;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.form-control:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control option[hidden] {
  color: #757575;
}

/* .modal-backdrop {
  position: fixed;
} */
</style>
