<script setup>
import { ref, watch, onMounted, defineEmits, computed } from "vue";
import { useRouter } from "vue-router";
import {
  formatDate,
  capitalize,
  formatToCreatedAt,
  formatPhoneNumber,
} from "@/utils";
import { useUserStore } from "@/store/user";
import { useSaleStore } from "@/store/sales";
import { t } from "@/utils/i18n";

const router = useRouter();
const userStore = useUserStore();
const saleStore = useSaleStore();
const userInfo = localStorage.getItem("user");
const userRole = JSON.parse(userInfo).role;
const carrierNames = ref({});
const carrierFilters = ref([]);
const companyFilters = ref([]);
const stateFilters = ref([]);
const sellerFilters = ref([]);
const processorFilters = ref([]);
const calidadFilters = ref([]);
const uidList = ref(saleStore.uidList);
const loading = ref(true);
const loadingData = ref(false); // New loading state for data fetching
const carriersLoaded = ref(false);
const companiesLoaded = ref(false);
const usersLoaded = ref(false);

const CACHE_DURATION = 24 * 60 * 60 * 1000; // 24 horas

const emit = defineEmits(["onSalesReassignRequested"]);

const translatedStatuses = computed(() => {
  let basicStatuses = [
    { text: t("dashboard.cards.assigned"), value: "ASSIGNED" },
    { text: t("dashboard.cards.rejected"), value: "REJECTED" },
    { text: t("dashboard.cards.corrected"), value: "CORRECTED" },
    { text: t("dashboard.cards.submitted"), value: "SUBMITTED" },
    { text: t("dashboard.cards.to_review"), value: "TO_REVIEW" },
    { text: t("dashboard.cards.pre_processing"), value: "PRE_PROCESSING" },
  ];

  if (userRole === "SELLER") {
    basicStatuses = [
      { text: t("dashboard.cards.draft"), value: "DRAFT" },
      ...basicStatuses,
    ];
  }

  return basicStatuses;
});

const cacheService = {
  set(key, data) {
    localStorage.setItem(
      key,
      JSON.stringify({
        data,
        timestamp: Date.now(),
      })
    );
  },

  get(key) {
    const cached = localStorage.getItem(key);
    if (!cached) return null;

    const { data, timestamp } = JSON.parse(cached);
    if (Date.now() - timestamp > CACHE_DURATION) {
      localStorage.removeItem(key);
      return null;
    }

    return data;
  },
};

// Computed columns with translations
const baseColumns = computed(() => [
  {
    title: "ID",
    dataIndex: "saleId",
    key: "saleId",
  },
  {
    title: t("dashboard.table.name"),
    dataIndex: "firstName",
    key: "name",
  },
  {
    title: t("dashboard.table.socialSecurityNumber"),
    dataIndex: "socialSecurityNumber",
    key: "socialSecurityNumber",
  },
  {
    title: t("dashboard.table.status"),
    dataIndex: "status",
    key: "status",
    filters: translatedStatuses.value,
    filterMultiple: true,
    onFilter: (value, record) => record.status === value,
  },
  {
    title: t("dashboard.table.birthDate"),
    dataIndex: "birthDate",
    key: "birthDate",
  },
  {
    title: t("dashboard.table.carrier"),
    dataIndex: "carrier",
    key: "carrier",
    filters: carrierFilters.value,
    onFilter: (value, record) => record.carrier.includes(value),
  },
  {
    title: t("dashboard.table.company"),
    dataIndex: ["sellerCompanyName"],
    key: "sellerCompanyName",
    filters: companyFilters.value,
    onFilter: (value, record) => record.sellerCompanyName === value,
  },
  {
    title: t("dashboard.table.creation"),
    dataIndex: "createdAt",
    key: "createdAt",
  },
  {
    title: t("dashboard.table.email"),
    dataIndex: "email",
  },
  {
    title: t("dashboard.table.phone"),
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: t("dashboard.table.submitted"),
    dataIndex: "submittedDate",
    key: "submittedDate",
  },
  {
    title: t("dashboard.table.state"),
    dataIndex: "state",
    key: "state",
    filters: stateFilters.value,
    onFilter: (value, record) => record.state === value,
  },
]);

// Computed properties for role-specific columns
const sellerColumn = computed(() => ({
  title: t("dashboard.table.seller"),
  dataIndex: "sellerFullName",
  key: "sellerFullName",
  filters: sellerFilters.value,
  onFilter: (value, record) => record.sellerFullName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

const calidadColumn = computed(() => ({
  title: t("dashboard.table.calidad"),
  dataIndex: "assignedToCalidadName",
  key: "assignedToCalidadName",
  filters: calidadFilters.value,
  onFilter: (value, record) => record.assignedToCalidadName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

const preprocessorColumn = computed(() => ({
  title: t("dashboard.table.preprocessor"),
  dataIndex: "asignedToNamePreProcessor",
  key: "asignedToNamePreProcessor",
}));

const processorColumn = computed(() => ({
  title: t("dashboard.table.processor"),
  dataIndex: "assignedToName",
  key: "assignedToName",
  filters: processorFilters.value,
  onFilter: (value, record) => record.assignedToName === value,
  filterSearch: (input, filter) => filter.value.indexOf(input) > -1,
}));

// Computed final columns based on user role
const columns = computed(() => {
  let finalColumns = [...baseColumns.value];

  switch (userRole) {
    case "SELLER":
      finalColumns.push(processorColumn.value);
      finalColumns.push(calidadColumn.value);
      finalColumns.push(preprocessorColumn.value);

      break;
    case "PROCESSOR":
      finalColumns.push(sellerColumn.value);
      finalColumns.push(calidadColumn.value);
      finalColumns.push(preprocessorColumn.value);

      break;
    default:
      finalColumns.push(
        sellerColumn.value,
        calidadColumn.value,
        preprocessorColumn.value,
        processorColumn.value
      );
  }

  return finalColumns;
});

const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
  activeKey: {
    type: String,
    default: "",
  },
  salesTotal: {
    type: Number,
    default: 0,
  },
});

const totalSales = computed(() => props.data[0]?.total || 0);

function handDetails(record) {
  router.push(
    record.status === "DRAFT"
      ? `/sales/new-sale/${record.document_id}`
      : `/sales/sale-detail?${record.document_id}`
  );
}

async function loadInitialData() {
  if (loadingData.value) return; // Prevent multiple calls
  loadingData.value = true;

  try {
    const promises = [];
    if (!carriersLoaded.value) promises.push(fetchCarrierNames());
    if (!companiesLoaded.value) promises.push(fetchCompanyNames());
    if (!usersLoaded.value) promises.push(loadUsersList());
    await Promise.all(promises);
  } catch (error) {
    console.error("Error loading initial data:", error);
  } finally {
    loadingData.value = false;
    loading.value = false;
  }
}

const fetchCarrierNames = async () => {
  if (carriersLoaded.value) return;
  const cached = cacheService.get("carriers");
  if (cached) {
    carrierNames.value = cached.names;
    carrierFilters.value = cached.filters;
    carriersLoaded.value = true;
    return;
  }
  try {
    const carriers = await userStore.fetchCarrier();
    if (carriers?.[0]?.list) {
      const names = {};
      const filters = carriers[0].list.map((carrier) => {
        names[carrier.id] = carrier.value;
        return { text: carrier.value, value: carrier.id };
      });
      cacheService.set("carriers", { names, filters });
      carrierNames.value = names;
      carrierFilters.value = filters;
      carriersLoaded.value = true;
    }
  } catch (error) {
    console.error("Error fetching carriers:", error);
  }
};

const fetchCompanyNames = async () => {
  if (companiesLoaded.value) return;
  const cached = cacheService.get("companies");
  if (cached) {
    companyFilters.value = cached;
    companiesLoaded.value = true;
    return;
  }
  try {
    const companiesProxy = await userStore.fetchCompanies();
    if (companiesProxy?.[0]) {
      const companies = Array.from(companiesProxy[0]).map((company) => ({
        value: company.id,
        text: company.value,
      }));
      cacheService.set("companies", companies);
      companyFilters.value = companies;
      companiesLoaded.value = true;
    }
  } catch (error) {
    console.error("Error fetching companies:", error);
  }
};

const states = [{ value: "Florida", text: "Florida" } /* ... */];
stateFilters.value = states;

const fullName = (firstName, lastName) => `${firstName} ${lastName}`;

const loadUsersList = async () => {
  if (usersLoaded.value) return;
  const cached = cacheService.get("userRoles");
  if (cached) {
    processorFilters.value = cached.processors;
    calidadFilters.value = cached.calidad;
    sellerFilters.value = cached.sellers;
    usersLoaded.value = true;
    return;
  }
  try {
    const users = await userStore.fetchUsers();
    const roleFilters = { processors: [], calidad: [], sellers: [] };
    users.forEach((user) => {
      const userFilter = {
        text: fullName(user.firstName, user.lastName),
        value: fullName(user.firstName, user.lastName),
      };
      switch (user.role) {
        case "PROCESSOR":
          roleFilters.processors.push(userFilter);
          break;
        case "CALIDAD":
          roleFilters.calidad.push(userFilter);
          break;
        case "SELLER":
          roleFilters.sellers.push(userFilter);
          break;
      }
    });
    processorFilters.value = roleFilters.processors;
    calidadFilters.value = roleFilters.calidad;
    sellerFilters.value = roleFilters.sellers;
    cacheService.set("userRoles", roleFilters);
    usersLoaded.value = true;
  } catch (error) {
    console.error("Error loading users:", error);
  }
};

const handleSalesReassign = (event, uid) => {
  const isChecked = event.target.checked;
  uidList.value[isChecked ? "push" : "splice"](
    isChecked ? uid : uidList.value.indexOf(uid),
    1
  );
  saleStore.updateUidList(uidList.value);
  emit("onSalesReassignRequested", saleStore.uidList);
};

const isChecked = (uid) => uidList.value.includes(uid);

// Watch for data changes (debounced)
watch(
  () => props.data,
  async (newData) => {
    if (newData && !loadingData.value) {
      await loadInitialData();
    }
  },
  { immediate: true }
);

onMounted(loadInitialData);
</script>

<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="table-responsive">
        <a-config-provider
          :theme="{
            token: {
              colorPrimary: '#44044b',
            },
          }"
        >
          <a-table
            :columns="columns"
            :loading="loading"
            :data-source="props.data"
            class="table-primary"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'saleId'">
                <label
                  v-if="
                    ([
                      'ASSIGNED',
                      'REJECTED',
                      'CORRECTED',
                      'SUBMITTED',
                    ].includes(record.status) &&
                      userRole === 'ADMIN') ||
                    userRole === 'SUPERVISOR' ||
                    userRole === 'JCALIDAD'
                  "
                  class="custom_check"
                >
                  <input
                    type="checkbox"
                    name="sales"
                    :disabled="['SUBMITTED'].includes(record.status)"
                    :data-sale-id="record.saleId"
                    :checked="isChecked(record.document_id)"
                    @click="
                      (event) => handleSalesReassign(event, record.document_id)
                    "
                  />
                  <span class="checkmark"></span>
                </label>

                <router-link
                  v-if="record.saleId"
                  to="/sale-detail"
                  @click="handDetails(record)"
                  class="invoice-link"
                >
                  {{ record.saleId }}
                </router-link>
              </template>

              <template v-if="column.key === 'name'">
                {{
                  record && record.firstName
                    ? `${record.firstName} ${
                        record.middleName ? record.middleName + " " : ""
                      }${record.lastName}`
                    : "Nombre no disponible"
                }}
              </template>
              <template v-if="column.key === 'birthDate'">
                {{
                  record && record.birthDate
                    ? formatDate(record.birthDate)
                    : "Date not available"
                }}
              </template>

              <template v-if="column.key === 'carrier'">
                {{ carrierNames[record.carrier] || "" }}
              </template>

              <template v-if="column.key === 'phone'">
                {{
                  record.phone
                    ? formatPhoneNumber(record.phone)
                    : "Date not available"
                }}
              </template>
              <template v-if="column.key === 'createdAt'">
                {{
                  record.createdAt
                    ? formatToCreatedAt(record.createdAt)
                    : "Date not available"
                }}
              </template>
              <template v-if="column.key === 'submittedDate'">
                {{
                  record.submittedDate
                    ? formatToCreatedAt(record.submittedDate)
                    : ""
                }}
              </template>

              <template v-else-if="column.key === 'status'">
                <span
                  v-if="record.status === 'DRAFT'"
                  class="badge bg-warning-light text-warning"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'ASSIGNED'"
                  class="badge bg-info-light text-info"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'CORRECTED'"
                  class="badge bg-light text-secondary"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'SUBMITTED'"
                  class="badge bg-success-light text-success rounded"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'REJECTED'"
                  class="badge bg-danger-light text-danger"
                >
                  {{ capitalize(record.status) }}
                </span>
                <span
                  v-else-if="record.status === 'TO_REVIEW'"
                  class="badge gb-purple-light text-purple"
                >
                  {{ capitalize(record.status) }}
                </span>

                <span
                  v-else-if="record.status === 'PRE_PROCESSING'"
                  class="badge gb-turquoise-light text-turquoise"
                >
                  {{ capitalize(record.status) }}
                </span>
              </template>
              <template v-if="column.key === 'sellerFullName'">
                {{
                  record.sellerFullName
                    ? record.sellerFullName
                    : "Seller not assigned"
                }}
              </template>
            </template>
          </a-table>
          <div class="total-sales">
            <strong>
              {{ t("dashboard.table.totalSales") }}: {{ totalSales }}</strong
            >
          </div>
        </a-config-provider>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-warnin-light {
  background-color: #fdb0b0;
  color: #fe0000 !important;
}

input[type="checkbox"][disabled] + .checkmark {
  cursor: initial;
  border: 1px solid #ebebeb;
  background: #f7f7f7;
}
</style>
