<script setup>
import { reactive, ref, defineProps, watch, onMounted } from "vue";
import moment from "moment";
import dayjs from "dayjs";
import { t } from "@/utils/i18n";
import { formatPhoneNumber } from "@/utils";

import { useSaleStore } from "@/store/sales";
import { Notyf } from "notyf";
import { useRoute } from "vue-router";
const route = useRoute();

const notyf = new Notyf();
const modalRef = ref(null);

const saleStore = useSaleStore();

const formRef = ref(null);
const statusPhone = ref("");
const responseDetails = ref(null);
const familyGroup = ref(false);

const uid = route.params.uid;
const isDuplicatePhoneModalVisible = ref(false);
const isDuplicatePhoneModalDetails = ref(false);
let phoneToConfirm = "";

const emit = defineEmits(["formValidated", "updateSaleData"]);

const props = defineProps({
  saleData: Object,
});

const disabledDate = (current) => {
  const eighteenYearsAgo = moment().subtract(18, "years").endOf("day");
  return current && current > eighteenYearsAgo;
};

const formatIncome = (event) => {
  const value = event.target.value.replace(/[^0-9]/g, "");
  const number = parseInt(value, 10);

  if (!isNaN(number) && number > 0) {
    const formattedValue = new Intl.NumberFormat("en-US", {
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
    formState.householdIncome = formattedValue;
  } else {
    formState.householdIncome = ""; // Mantenerlo vacío si no hay valor
  }
};

const formState = reactive({
  firstName: props.saleData?.personalInfo?.firstName ?? "",
  middleName: props.saleData?.personalInfo?.middleName ?? "",
  lastName: props.saleData?.personalInfo?.lastName ?? "",
  gender: props.saleData?.personalInfo?.gender ?? null,
  birthDate: props.saleData?.personalInfo?.birthDate
    ? moment(props.saleData.personalInfo.birthDate)
    : undefined,
  socialSecurityNumber:
    props.saleData?.personalInfo?.socialSecurityNumber ?? "",
  inmigrationStatus: props.saleData?.personalInfo?.inmigrationStatus ?? null,
  phone: props.saleData?.personalInfo?.phone ?? "",
  email: props.saleData?.personalInfo?.email ?? "",
  address: props.saleData?.personalInfo?.address ?? "",
  zipCode: props.saleData?.personalInfo?.zipCode ?? "",
  state: props.saleData?.personalInfo?.state ?? null,
  married: props.saleData?.personalInfo?.married ?? false,
  familySize: props.saleData?.personalInfo?.familySize ?? "",
  householdIncome: props.saleData?.personalInfo?.householdIncome ?? "",
  requestAccountingServices:
    props.saleData?.personalInfo?.requestAccountingServices ?? false,
  familyGroupId: props.saleData?.personalInfo?.familyGroupId ?? false,
  uidOriginal: props.saleData?.personalInfo?.uidOriginal ?? "",
});

const states = [
  { value: "Florida", label: "Florida" },
  { value: "Alabama", label: "Alabama" },
  { value: "Arizona", label: "Arizona" },
  { value: "Georgia", label: "Georgia" },
  { value: "Illinois", label: "Illinois" },
  { value: "Louisiana", label: "Louisiana" },
  { value: "Missouri", label: "Missouri" },
  { value: "North Carolina", label: "North Carolina" },
  { value: "Ohio", label: "Ohio" },
  { value: "South Carolina", label: "South Carolina" },
  { value: "Tennessee", label: "Tennessee" },
  { value: "Texas", label: "Texas" },
];

const rules = {
  firstName: [
    { required: true, message: "Please input first name", trigger: "change" },
    { min: 2, max: 30, message: "Length should be 2 to 30", trigger: "blur" },
  ],
  middleName: [{ required: false, message: "", trigger: "change" }],
  lastName: [
    { required: true, message: "Please input last name", trigger: "change" },
    { min: 3, max: 30, message: "Length should be 3 to 30", trigger: "blur" },
  ],
  gender: [
    {
      required: true,
      message: "Please select your gender.",
      trigger: "change",
    },
  ],
  birthDate: [
    {
      required: true,
      message: "Please select your date of birth.",
      trigger: "change",
      type: "object",
    },
  ],
  socialSecurityNumber: [
    {
      required: false,
      message: "Please input social security number",
      trigger: "change",
    },
    { min: 9, max: 9, message: "Length should be 9", trigger: "blur" },
  ],
  inmigrationStatus: [
    {
      required: true,
      message: "Please select your immigration status.",
      trigger: "change",
    },
  ],
  phone: [
    { required: true, message: "Please input phone number", trigger: "change" },
    { min: 13, message: "Length must be 10 or more", trigger: "blur" },
  ],
  email: [
    { required: true, message: "Please input email", trigger: "change" },
    {
      type: "email",
      message: "Email is not a valid email!",
      trigger: "change",
    },
  ],
  address: [
    { required: true, message: "Please input address", trigger: "change" },
  ],
  zipCode: [
    { required: true, message: "Please input zip code", trigger: "change" },
    { min: 5, max: 5, message: "Length should be 5", trigger: "blur" },
  ],
  state: [
    { required: true, message: "Please select your state.", trigger: "change" },
  ],
  familySize: [
    { required: true, message: "Please input family size", trigger: "change" },
  ],
  householdIncome: [
    {
      required: true,
      message: "Please input household income",
      trigger: "change",
    },
  ],
};

const validatePhone = async (value) => {
  const newValue = value.trim().replace(/[\s()\-]/g, "");
  try {
    const isValidatePhone = await saleStore.isSaleExisting(newValue);
    console.log(
      "Tipo de response: ",
      typeof isValidatePhone,
      "Valor ",
      isValidatePhone
    );
    if (isValidatePhone) {
      statusPhone.value = "error";
      emit("formValidated", false);
      phoneToConfirm = newValue;
      openDuplicatePhoneModal(); // Pass the phone number to the modal
    } else {
      emit("formValidated", true);
      statusPhone.value = "";
    }
  } catch (error) {
    console.error("Error al validar el número de teléfono:", error);
  }
};

function openDuplicatePhoneModal() {
  isDuplicatePhoneModalVisible.value = true;
}

function closeDuplicatePhoneModal() {
  isDuplicatePhoneModalVisible.value = false;
}

function closeDuplicatePhoneModalDetails() {
  isDuplicatePhoneModalDetails.value = false;
}
const updateFamiliyPhone = async () => {
  const isValidatePhoneDetails = await saleStore.isSaleDetailsPhone(
    phoneToConfirm
  );
  if (isValidatePhoneDetails) {
    responseDetails.value = isValidatePhoneDetails[0];
    closeDuplicatePhoneModal();
    isDuplicatePhoneModalDetails.value = true;
  }
};
const updateFamilyGroup = async () => {
  familyGroup.value = true;
  statusPhone.value = "";
  closeDuplicatePhoneModalDetails();
};

const validateForm = () => {
  if (formRef.value) {
    formRef.value
      .validate()
      .then(() => {
        emit("formValidated", true);
        if (statusPhone.value === "error") emit("formValidated", false);
        console.log("responseDetails", responseDetails.value);
        const personalInfo = {
          firstName: formState.firstName.trim(),
          middleName: formState.middleName.trim(),
          lastName: formState.lastName.trim(),
          gender: formState.gender,
          birthDate: dayjs(formState.birthDate).format("YYYY-MM-DD"),
          socialSecurityNumber: formState.socialSecurityNumber,
          inmigrationStatus: formState.inmigrationStatus,
          phone: formState.phone.trim().replace(/[\s()\-]/g, ""),
          email: formState.email,
          address: formState.address.trim(),
          zipCode: formState.zipCode,
          state: formState.state,
          married: formState.married,
          familySize: formState.familySize,
          householdIncome: formState.householdIncome,
          requestAccountingServices: formState.requestAccountingServices,
          familyGroupId: familyGroup.value == true ? "true" : "false",
          uidOriginal: responseDetails.value?.uid ?? null,
        };

        emit("updateSaleData", { personalInfo: personalInfo });
      })
      .catch((error) => {
        console.log(error);
        emit("formValidated", false);
      });
  } else {
    console.error("Form ref is not available");
    emit("formValidated", false);
  }
};

watch(
  formState,
  () => {
    validateForm();
  },
  { deep: true }
);

onMounted(() => {
  validateForm();
});

defineExpose({
  openDuplicatePhoneModal,
  closeDuplicatePhoneModal,
});
</script>
<template>
  <div class="modal-overlay" v-if="isDuplicatePhoneModalVisible">
    <div class="new-modal modal-dialog-centered">
      <div class="modal-content" style="padding: auto">
        <div class="modal-header border-2">
          <h5
            class="modal-title"
            style="color: #ff4d4f; margin: 0 auto; font-size: 25px"
          >
            {{ t("sales.newSale.txtTitle") }}
          </h5>
        </div>
        <div class="modal-body" style="text-align: center; padding: 10%">
          <p>{{ t("sales.newSale.textModal1") }}</p>
          <p>{{ t("sales.newSale.textModal2") }}</p>
        </div>
        <div class="modal-footer" style="justify-content: space-between">
          <button
            type="button"
            class="btn btn-secondary"
            @click="updateFamiliyPhone"
          >
            Si
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeDuplicatePhoneModal"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-overlay" v-if="isDuplicatePhoneModalDetails">
    <div class="new-modal modal-dialog-centered">
      <div class="modal-content" style="padding: 10px">
        <div class="modal-header border-2">
          <h5
            class="modal-title"
            style="color: #ff4d4f; margin: 0 auto; font-size: 25px"
          >
            {{ t("sales.newSale.txtTitle") }}
          </h5>
        </div>
        <div class="modal-body" style="text-align: center; padding: 10%">
          <p style="font-size: 20px">
            {{ t("sales.newSale.textModalTwo") }}
          </p>
          <p style="font-size: 18px">
            <strong>{{ formatPhoneNumber(phoneToConfirm) }}</strong>
          </p>
          <p style="font-size: 20px">
            {{ t("sales.newSale.textModalThree") }}
          </p>
          <p style="font-size: 18px">
            <strong>{{ responseDetails.fullName }} </strong>,
            {{ t("sales.newSale.textModalCompany") }}
          </p>
          <p style="font-size: 18px">
            <strong>{{ responseDetails.companyName }}</strong>
          </p>
          <p style="font-size: 20px">
            {{ t("sales.newSale.textModalFour") }}
          </p>
        </div>
        <div
          class="modal-footer"
          style="justify-content: space-between; padding: 10px"
        >
          <button
            type="button"
            class="btn btn-secondary"
            @click="updateFamilyGroup"
          >
            Si
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            @click="closeDuplicatePhoneModalDetails"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--container-->
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body container-form">
            <h5 class="card-title mb-5">
              {{ t("sales.details.primary.primary") }}
            </h5>
            <a-form ref="formRef" :model="formState" :rules="rules">
              <div class="row">
                <div class="col-md-4">
                  <a-form-item name="phone">
                    <label for="phone" class="form-label">
                      {{ t("sales.details.primary.phone") }}
                    </label>
                    <a-input
                      size="large"
                      v-model:value="formState.phone"
                      v-mask="'(###)###-####'"
                      id="phone"
                      :disabled="uid ? true : false"
                      :status="statusPhone"
                      @input="uid ? '' : validatePhone(formState.phone)"
                    />
                    <div
                      class="ant-form-item-explain-error"
                      v-if="statusPhone === 'error'"
                    >
                      Phone number is already
                    </div>
                  </a-form-item>
                </div>
                <div class="col-md-4">
                  <a-form-item name="firstName">
                    <label for="firstName" class="form-label" name="firstName">
                      {{ t("sales.details.primary.firstName") }}</label
                    >
                    <a-input
                      v-model:value="formState.firstName"
                      size="large"
                      style="width: 100%"
                      id="firstName"
                      placeholder="Enter first name"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-4">
                  <a-form-item name="middleName">
                    <label
                      for="middleName"
                      class="form-label no-required"
                      name="middleName"
                    >
                      {{ t("sales.details.primary.middlename") }}</label
                    >
                    <a-input
                      v-model:value="formState.middleName"
                      size="large"
                      id="middleName"
                      placeholder="Enter middle name"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="lastName">
                    <label for="lastName" class="form-label" name="lastName">
                      {{ t("sales.details.primary.lastName") }}</label
                    >
                    <a-input
                      v-model:value="formState.lastName"
                      size="large"
                      id="lastName"
                      placeholder="Enter last name"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item name="birthDate">
                    <label for="dateOfBirth" class="form-label">
                      {{ t("sales.details.primary.birthDate") }}
                    </label>
                    <a-date-picker
                      v-model:value="formState.birthDate"
                      :format="'MMM/DD/YYYY'"
                      size="large"
                      class="d-block"
                      id="dateOfBirth"
                      placeholder="MM/DD/YYYY"
                      :disabled-date="disabledDate"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="gender">
                    <label for="gender" class="form-label">
                      {{ t("sales.details.primary.gender") }}
                    </label>
                    <a-select
                      v-model:value="formState.gender"
                      size="large"
                      id="gender"
                      placeholder="select"
                    >
                      <a-select-option value="Women">Women</a-select-option>
                      <a-select-option value="Men">Men</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item name="socialSecurityNumber">
                    <label
                      for="socialSecurityNumber"
                      class="form-label no-required"
                    >
                      {{ t("sales.details.primary.socialSecurityNumber") }}
                    </label>
                    <a-input
                      size="large"
                      v-model:value="formState.socialSecurityNumber"
                      placeholder="Enter social security number"
                      id="socialSecurityNumber"
                      class="d-block"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="inmigrationStatus">
                    <label for="inmigrationStatus" class="form-label">
                      {{ t("sales.details.primary.inmigrationStatus") }}
                    </label>
                    <a-select
                      v-model:value="formState.inmigrationStatus"
                      size="large"
                      id="inmigrationStatus"
                      placeholder="select"
                    >
                      <a-select-option value="Resident"
                        >Resident</a-select-option
                      >
                      <a-select-option value="Citizen">Citizen</a-select-option>
                      <a-select-option value="Work Permit"
                        >Work Permit</a-select-option
                      >
                      <a-select-option value="Others">Others</a-select-option>
                    </a-select>
                  </a-form-item>
                </div>

                <div class="col-md-6">
                  <a-form-item name="email">
                    <label for="email" class="form-label">
                      {{ t("sales.details.primary.email") }}
                    </label>
                    <a-input
                      size="large"
                      v-model:value="formState.email"
                      placeholder="Enter email"
                      id="email"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="address">
                    <label for="address" class="form-label">
                      {{ t("sales.details.primary.address") }}
                    </label>
                    <a-input
                      size="large"
                      v-model:value="formState.address"
                      placeholder="Enter address"
                      id="address"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-6">
                  <a-form-item name="zipCode">
                    <label for="zipCode" class="form-label">
                      {{ t("sales.details.primary.zipCode") }}</label
                    >
                    <a-input
                      size="large"
                      type="number"
                      v-model:value="formState.zipCode"
                      placeholder="Enter zip code"
                      id="zipCode"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="state">
                    <label for="state" class="form-label">
                      {{ t("sales.details.primary.state") }}
                    </label>
                    <a-select
                      v-model:value="formState.state"
                      size="large"
                      id="state"
                      :options="states"
                      placeholder="select"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-3 d-flex align-items-center">
                  <a-form-item name="married" class="mb-0">
                    <a-checkbox
                      v-model:checked="formState.married"
                      class="mb-0"
                    >
                      {{ t("sales.details.primary.married") }}</a-checkbox
                    >
                  </a-form-item>
                </div>
                <div class="col-md-3">
                  <a-form-item name="familySize">
                    <label for="familySize" class="form-label">
                      {{ t("sales.details.primary.familySize") }}</label
                    >
                    <a-input
                      size="large"
                      v-model:value="formState.familySize"
                      placeholder="Enter family size"
                      id="familySize"
                    />
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <a-form-item name="householdIncome">
                    <label for="householdIncome" class="form-label">
                      {{ t("sales.details.primary.household") }}</label
                    >
                    <a-input
                      prefix="$"
                      size="large"
                      v-model:value="formState.householdIncome"
                      placeholder="Enter household income"
                      @input="formatIncome"
                      id="householdIncome"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <a-form-item name="requestAccountingServices" class="mb-0">
                    <a-checkbox
                      v-model:checked="formState.requestAccountingServices"
                      >{{ t("sales.details.primary.services") }}</a-checkbox
                    >
                  </a-form-item>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
#dateOfBirth {
  text-transform: uppercase;
}
.new-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001; /* Mayor que el overlay para que aparezca encima */
  padding: 20px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo negro semi-transparente */
  z-index: 1000; /* Asegura que esté por encima del contenido normal */
}
</style>
